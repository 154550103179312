import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import Episodes from './pages/home.js';
import GetInvolvedPage from './pages/get-involved.js';
import Upload from './pages/upload';

import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'Sleepwell UK';
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={ <Episodes/> }></Route>
          <Route exact path="/participate" element={ <GetInvolvedPage/> }></Route>
          <Route exact path="/upload" element={ <Upload/> }></Route>
          <Route exact path="/studios" element={ <Episodes/> }></Route>
        </Routes>
      </Router>
      <Outlet/>
    </div>
  )
}

export default App;
