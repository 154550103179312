import * as React from 'react';

import './footer.css';

class Footer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {menuOpen: false};
        this.openMenu = this.openMenu.bind(this);
    }

    openMenu(event) {
        console.log(event);
        var state = Object.assign(this.state, null);
        state.menuOpen = !state.menuOpen;
        this.setState(state);
    }
  
    render() {
        return (
            <>
                <div className='header-footer'>
                    
                </div>
                <footer className="App-footer">
                </footer>
            </>
        );
    }
}

export default Footer
