import * as React from 'react';

import { FaArrowDown } from 'react-icons/fa';

import './get-involved.css';

import Header from '../components/header.js';
import Footer from '../components/footer.js';

class GetInvolvedPage extends React.Component {
    
    constructor(props) {
        super(props);
        //this.handlePage = this.handlePage.bind(this);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Header/>
                <Involved/>
                <Footer/>
            </>
        );
    }
}

const Involved = (props) => {
    return (
        <div className="involved-page">
            Thanks for your interest in becoming part of our TV show.
            <div className='involved-content'>
                <p>
                    We make 5 shows per week from our London studio.
                </p>
                <p>
                    <small>Get the chance to blow the audience away with your talent:</small>
                </p>
                <div className='involved-list'>
                    <ul>
                        <li>Become a household name</li>
                        <li>Make the cut and get exposure for your talent</li>
                        <li>First access to new music instrumentals</li>
                        <li>Be featured in official events and parties</li>
                    </ul>
                </div>
                <p>
                    <FaArrowDown/>
                </p>
            </div>
            <h3>Contact us:</h3>
            <div>Artist name:</div>
            <div>
                <input className='contact-input'/>
            </div>
            <div>Artist email:</div>
            <div>
                <input className='contact-input'/>
            </div>
            <div>Artist phone:</div>
            <div>
                <input type='phone' className='contact-input'/>
            </div>
            <div>Social profiles:</div>
            <div>
                <textarea className='contact-input' style={{}}/>
            </div>
            <button className='contact-button'>SUBMIT</button>
        </div>
    )
}

export default GetInvolvedPage;
