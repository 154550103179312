import * as React from 'react';

import './home.css';

class Upload extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.toggleUpload = this.toggleUpload.bind(this);
    }

    toggleUpload () {
        this.setState({
            open: true
        });
        console.log("toggle", this.state.open);
    }
  
    render() {
        return (
            <>
                { !this.state.open && <button onClick={this.toggleUpload}>UPLOAD FILE</button> }
                { this.state.open && UploadForm() }
            </>
        );
    }
}

function UploadForm() {
    return (
        <form
        encType="multipart/form-data"
        action="https://europe-west2-sleepwelluk.cloudfunctions.net/api-upload-image"
        method="post"
        >
        <input type="file" name="myFile" />
        <input type="submit" value="upload" />
        </form>
    )
}

export default Upload;
