import * as React from 'react';

//import { TfiClose} from "react-icons/tfi";
//import { FaPlay } from 'react-icons/fa';

import './item.css';


class Item extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        };
        console.log(this.props);
    }
  
    render() {
        return (
            <div className='item'>
                <div style={{width:"20vw", backgroundPosition: "center", backgroundSize: "cover", backgroundImage:"url(https://storage.googleapis.com/sleepwell-media/flyers/flyer_goose_april_28.jpg)"}}>
                </div>
                <div className='item-details'>
                    <div className='item-title'>
                        { this.state.data.when }
                    </div>
                    <div className='item-spacer'/>
                    <div className='item-title'>
                        <div style={{color:"gold"}}>{ this.state.data.doors }</div>
                        <div style={{color:"gold"}}><h3>{ this.state.data.what }</h3></div>
                    </div>
                    <div className='item-spacer'/>
                    <div className='item-description'>
                        { this.state.data.who }
                    </div>
                    <div className='item-spacer'/>
                    <div className='item-description' style={{color:"gold"}}>
                        { this.state.data["Name (from where)"]}, { this.state.data["address (from where)"] }, { this.state.data["postcode (from where)"] }
                    </div>
                    <div className='item-spacer'/>
                    <div className='item-description'>
                        Tickets: £{ this.state.data.tickets }
                    </div>
                </div> 
            </div>
        );
    }
}

export default Item;
