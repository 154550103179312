import * as React from 'react';
import { TfiMenu } from "react-icons/tfi";

import Menu from "./menu.js"

import './header.css';

class Header extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {menuOpen: false};
        this.openMenu = this.openMenu.bind(this);
    }

    openMenu(event) {
        console.log(event);
        var state = Object.assign(this.state, null);
        state.menuOpen = !state.menuOpen;
        this.setState(state);
    }
  
    render() {
        return (
            <>
                <header className="App-header">
                    <div className='header-logo'>
                        <div>
                            <strong>Sleepwell Productions</strong>
                        </div>
                    </div>
                    <div className='header-links'>
                        <div className='header-link'>
                            <a href="/participate">
                                Gallery
                            </a>
                        </div>
                        <div className='header-link'>
                            <a href="/participate">
                                Contact Us
                            </a>
                        </div>
                        <div className='header-link'>
                            <a href="/participate">
                                Contact Us
                            </a>
                        </div>

                        <TfiMenu className='menu-button' style={{marginRight:"3vh",marginLeft:"3vh"}} onClick={ this.openMenu }/>
                    </div>
                </header>
                {
                    this.state.menuOpen  && <Menu toggle={this.openMenu}/>
                }
                <div className='header-footer'>            
                </div>
                <div className="header-tabs">
                    <div className="header-tab">
                        <a href='/' className='header-menu-link'>Events</a>
                    </div>
                </div>
            </>
        );
    }
}

export default Header;
