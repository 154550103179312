import * as React from 'react';
import { TfiClose} from "react-icons/tfi";

import './menu.css';

class Menu extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
        console.log(this.props);
    }
  
    render() {
        return (
            <div className='menu'>
                <div className='menu-header'>
                    <TfiClose style={{marginRight:"3vh",marginLeft:"3vh", cursor:"pointer"}} onClick={this.props.toggle} />
                </div>
                <div className='menu-items'>
                    <div className='menu-item'>
                        Login
                    </div>
                    <div className='menu-item'>
                        Signup
                    </div>
                </div>
            </div>
        );
    }
}

export default Menu;
