import * as React from 'react';
import Footer from '../components/footer.js';
import Item from '../components/item.js';

import './home.css';

class Episodes extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        fetch("https://europe-west2-sleepwelluk.cloudfunctions.net/api-events")
        .then(response => response.json())
        .then(data => this.setState({items:data}));
    }
  
    render() {
        return (
            <>
                <div className="episodes">
                
                    {
                        this.state.items.map(function (item, i) {
                            return (<Item key={i} data={item}/>)
                        })
                    }

                </div>
                <Footer/>
            </>
        );
    }
}

export default Episodes;
